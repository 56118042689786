.home-banner {
  height: 200px;
  color: var(--primary-color);
  position: relative;
}
.home-banner img {
  position: absolute;
  left: 50%;
  bottom: -123px;
  transform: translateX(-50%);
  border-radius: 50%;
}

.home-title {
  margin-top: 120px;
  text-align: center;
}
.home-title h1 {
  font-weight: bolder;
  color: var(--primary-text);
  font-size: 2rem;
}
.select-card-no {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.select-card-no select {
  width: 300px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
}
.select-card-no button {
  width: 130px;
}
.your-details h2 {
  text-align: center;
}
.your-details-inner {
  margin: 48px auto;
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
}
.your-details-inner > div {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  text-align: center;
  /* border: 0.5px solid #333; */
  padding: 10px 10px;
  border-radius: 8px;
  background: #f8f9fa;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.your-details-inner > div b {
  color: var(--primary-text);
}

@media (max-width: 500px) {
  .your-details-inner {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .employees_bar {
    width: 128%;
  }
}

@media (min-width: 1200px) {
  .your-details-inner {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .your-details-inner > div {
    flex-wrap: wrap;
  }
}
.min_3_word {
  position: absolute;
  top: 40px;
  color: var(--primary-text);
  font-size: 13px;
  font-weight: 500;
  left: 5px;
}
.log-o-btn{
  background-color: #fff !important;
  color: #212529 !important;
  margin: 10px !important;
  padding: 4px 12px !important;
}