:root {
  --primary-color: #f7b875;
  --primary-text: #b9660e;
}
* {
  padding: 0px;
  margin: 0px;
}
p {
  margin: 0px;
}
.pointer {
  cursor: pointer;
}

#success {
  color: green;
}

.bg-white {
  background-color: #fff;
}

.check-btn {
  /* padding: 23px 90px; */
  font-size: 24px;
  width: 200px;
  height: 70px;
}

.color-set {
  background: #e6e4e4;
}

.bg-orange {
  background-color: #f7b875;
}

.banner-header {
  background-color: #f7b875;
  /* height: 120px; */
  width: 100%;
  padding: 15px 10px;
}

.logo {
  height: 100px;
  width: 100px;
  border-radius: 64px;
}

.input-field {
  width: 500px;
  margin: 0px auto;
}

.mt-150 {
  margin-top: 35px;
}

a.buttons-collection {
  margin-left: 1em;
}

.table {
  padding: 10px;
}

/* .logout-button {
  height: 30px;
  width: 65px;
  padding: 0px 4px;
} */

.page-menue {
  color: #b9660e;
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

a {
  color: #b9660e;
  text-decoration: none;
}

a:hover {
  color: #b9660e;
  border-bottom: 1px solid #b9660e;
  text-decoration: none;
}

.active.show {
  color: blue;
}

#tab7 {
  color: blue;
}

a:active {
  color: blue;
  border-bottom: 1px solid #b9660e;
  text-decoration: none;
}

@media only screen and (max-width: 1600px) {
  #example {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1200px) {
  #example4 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1024px) {
  #example5 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1300px) {
  #example6 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1300px) {
  #example8 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1400px) {
  #example10 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1080px) {
  #example11 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 1023px) {
  #example1,
  #example2,
  #example3 {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  div.dt-buttons {
    text-align: left !important;
  }

  /* .tabbable {
    width: 100% !important;
    overflow-x: none;
  } */

  .input-field {
    width: 100%;
  }

  .fz-20 {
    font-size: 20px;
  }

  .refresh-button {
    font-size: 12px;
    margin-top: 20px;
  }

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
}

.nav-tabs > li {
  float: none;
}

.tabbable {
  width: 100%;
  margin: auto;
}

.reload {
  background: #f7b875 !important;
  text-transform: none !important;
}
.reload:hover {
  color: #fff !important;
  background: #f7b875 !important;
}

.filter {
  background: #17a2b8 !important;
  text-transform: none !important;
}
.filter:hover {
  color: #fff;
}

.adduser {
  text-transform: none !important;
}
.adduser:hover {
  color: #fff !important;
  background: #24a0ed !important;
}
.adduser {
  background: #24a0ed !important;
}

.text-green {
  color: green !important;
}

.submit_btn:hover {
  background-color: #f7b875 !important;
}

.submit_btn:active {
  background-color: #f7b875 !important;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0px;
}

/* .my_table .MuiDataGrid-toolbarContainer{
  background-color: #F7B875 !important;
} */

.my_table .MuiButton-sizeSmall {
  color: #000 !important;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background-color: transparent;
  border: 0;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.btn-sec {
  justify-content: end;
  margin-left: auto;
}

button {
  text-transform: capitalize !important;
}

@media (max-width: 767px) {
  .header-logo {
    width: 80px !important;
    height: 80px !important;
  }
}
@media (max-width: 424px) {
  .header-logo {
    width: 60px !important;
    height: 60px !important;
  }
}

.css-1ex1afd-MuiTableCell-root {
  padding: 12px 16px !important;
}

.css-1ygcj2i-MuiTableCell-root {
  padding: 0 16px 12px 16px !important;
}
.date_pick_customise .MuiFormControl-root {
  width: 100%;
}
.relative {
  position: relative;
}

.employees_bar {
  position: absolute;
  max-height: 300px;

  top: 60px;
  width: 100%;
  background: #fff;
  z-index: 999999;
  border-radius: 8px;
  overflow: auto;
}
.close_icon_for_emp_field {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 99999;
  background: #fff;
  cursor: pointer;
}

.MuiInputBase-input {
  padding: 7.5px 12px !important;
}

.modal-footer {
  margin-top: 15px;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: unset !important;
}
.MuiDataGrid-cell {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: unset !important;
}
