.nav-menu {
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: -300px;
  width: 260px;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  z-index: 999;
  padding: 20px;
  transition: 0.5s ease-in;
}
.active-nav {
  left: 0 !important;
}
.nav-menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.nav-menu-items li {
  padding: 10px;
  color: var(--primary-text);
}
.menu-btn {
  margin: 0 10px;
  color: #fff;
  cursor: pointer;
}
@media (min-width: 768px) {
  .menu-btn {
    display: none !important;
  }
}
